import styled from "styled-components"

const BareButton = styled.button`
  margin: 0;
  padding: 0;
  color: inherit;
  font: inherit;
  text-align: center;
  text-decoration: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  user-select: none;

  &:focus {
    outline: none;
  }
`

export default BareButton

import React from "react"
import styled from "styled-components"
import { theme, device } from "../../../theme"

const StyledSection = styled.section`
  display: flex;
  justify-content: center;
  position: relative;
`

const SectionContainer = styled.div`
  padding: 50px;

  @media ${device.tablet} {
    padding: 40px 20px;
  }
`

const SectionTitle = styled.div`
  font-size: 30px;
  text-align: center;
  color: ${theme.primary};
`

const SectionContent = styled.div`
  padding: 50px 0px;
`

const Section = React.forwardRef(
  ({ title, id, centerTitle, children }, forwardedRef) => (
    <StyledSection>
      <SectionContainer ref={forwardedRef} id={id}>
        <SectionTitle center={centerTitle}>{title}</SectionTitle>
        <SectionContent>{children}</SectionContent>
      </SectionContainer>
    </StyledSection>
  ),
)

export default Section

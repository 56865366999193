import styled from "styled-components"
import Section from "../../../components/core/section"
import Item from "./item"
import Countdown from 'react-countdown'
import { countdownDate } from '../../countdown' 

const BASIC_PACK_ID = process.env.REACT_APP_ARGON_PACK_ID
const FREE_PACK_ID = process.env.REACT_APP_ARGON_FREE_PACK_ID

const Ul = styled.ul`
  padding-inline-start: 20px;
  margin-block-start: 0;
  text-align: left;
  & li  {
    line-height: 22px;
  }
  & ul {
    line-height: 22px;
  }
`

const CountdownWrapper = styled.div`
  margin-bottom: 50px;
  `
  
  const CountdownContainer = styled.div`
  display: inline-block;
  margin-top: 10px;
  border: 1px solid #999999;
  padding: 10px;
  font-size: 40px;
`

const SpecialEditionCopy = () => (
  <>
    <CountdownWrapper>
      <CountdownContainer>
      <Countdown date={countdownDate} />
      </CountdownContainer>
    </CountdownWrapper>

    <strong>AUCTION ENDS FRIDAY, MAY 21 @ ~12PM EST</strong><br/><br/>
    <Ul>
      <li>The official 1/1 DR.EV4L album NFT includes both the front and back cover art animations</li>
      <li>Exclusive day-in-life footage of Young Nudy</li>
      <li>High resolution album art</li>
      <li>Merchandise graphic animation</li>
      <li>Certificate of Authenticity</li>
    </Ul>
    <strong>ONE-OF-A-KIND REDEEMABLES FOR THE WINNER:</strong>
    <br/><br/>
    <Ul>
      <li>(4) VIP tickets to a show including a meet-and-greet with Young Nudy, backstage passes, drink tickets, etc</li>
      <li>Exclusive polaroid photos from behind-the-scenes. Signed by Young Nudy.</li>
      <li>Full merch bundle of winner’s choosing.</li>
      <li>Future rewards exclusively for the token holder.</li>
    </Ul>
  </>
)

const BasicEditionCopy = () => (
  <>
    <strong>CAPPED AT 400 EDITIONS<br/>MINTING ENDS ON JUNE 1ST @ ~12PM EST</strong>
    <br/><br/>
    <Ul>
      <li>The official DR.EV4L album NFT includes the front cover art animation</li>
      <li>Exclusive day-in-life footage of Young Nudy</li>
      <li>High resolution album art</li>
      <li>Merchandise graphic animation</li>
      <li>Certificate of Authenticity</li>
    </Ul>
    <strong>REDEEMABLES:</strong>
    <br/><br/>
    <Ul>
      <li>$100 in merch redeemables</li>
      <li>Future rewards exclusively for the token holder</li>
    </Ul>
  </>
)

const FreeEditionCopy = () => (
  <>
    <strong>BUYER PAYS GAS FEES ONLY</strong>
    <br/><br/>
    <Ul>
      <li>The official DR.EV4L album NFT includes the front cover art animation</li>
      <li>Certificate of Authenticity</li>
    </Ul>
  </>
)

const NftEditions = () => (
  <Section id={"nfts"}>
    <Item 
      title={"1/1 NFT Auction"}
      auction
      edition="auction"
    >
      <SpecialEditionCopy />
    </Item>
    <Item 
      title={"NFT Edition"}
      packId={BASIC_PACK_ID}
      edition="basic"
    >
      <BasicEditionCopy />
    </Item>
    <Item 
      title={"Free NFT Edition"}
      packId={FREE_PACK_ID}
      edition="free"
    >
      <FreeEditionCopy />
    </Item>
  </Section>
)

export default NftEditions

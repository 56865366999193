
import styled from 'styled-components'
import Logo from '../../assets/argon-logo.svg'
import {device} from '../../theme'
const Container = styled.div`
    position: fixed;
    bottom: 20px;
    right: 20px;
    
    & img {
        width: 40px;
        height: 40px;

        @media ${device.tablet} {
            width: 30px;
            height: 30px;
        }
    }

`

const Link = styled.a`
    display: block;
`

const ArgonLogo = () => (
<Container>
    <Link href={process.env.REACT_APP_ARGON_URL} target="_blank" rel="noopener noreferrer">
        <img src={Logo} alt="argon logo" />
    </Link>
</Container>
)

export default ArgonLogo
import { web3, GIVEN_PROVIDER } from "./web3"

import contract from "../contracts/artifacts/contracts/argonNFT.sol/argonNFT.json"

const CONTRACT_ADDRESS = process.env.REACT_APP_ARGON_CONTRACT_ADDRESS
const BASIC_PACK_ID = process.env.REACT_APP_ARGON_PACK_ID
const FREE_PACK_ID = process.env.REACT_APP_ARGON_FREE_PACK_ID
const AUCTION_ADDRESS = process.env.REACT_APP_ARGON_AUCTION_ADDRESS
const AUCTION_PACK_ID = process.env.REACT_APP_ARGON_AUCTION_PACK_ID




// common

const getContract = async () => {
  let nftContract

  try {
    nftContract = new web3.eth.Contract(contract.abi, CONTRACT_ADDRESS) 
  } catch (e) {
    console.log(e)
  }
  return nftContract
}

export const getNftTypeMap = async (packId) => {
  let nft
  try {
    const nftContract = await getContract()
    if (!nftContract) return

    nft = await nftContract.methods.NFTTypeMap(packId).call()

  } catch (e) {
    console.log(e)
  }

  if (!nft) return
  const { count, minted, price } = nft

  return {
    count,
    minted,
    price: web3.utils.fromWei(price, 'ether')
  }
}

const getNFTPrice = async (packId) => {
  let price
  try {
    const nftContract = await getContract()
    if (!nftContract) return

    price = await nftContract.methods.getNFTPrice(packId).call()
  } catch (e) {
    console.log(e)
  }

  return price
}

const getNftJson = async (id) => {
  const uri = await getNftUri(id)
  if (!uri) return

  let obj = null

  try {
    obj = await (await fetch(uri)).json()
  } catch (e) {
    console.log("error fetching NFT json")
  }

  return obj
}

// account

export const getAccount = async () => {
  if (!GIVEN_PROVIDER) return
  let accounts 

  try {
    accounts = await web3.eth.getAccounts()
  } catch (e) {
    console.log(e)
  }

  if (!accounts || accounts.length < 1) return

  return accounts[0]
}

export const getNetworkType = async () => {
  let network
  if (window.ethereum) network = await window.ethereum.request({ method: 'eth_chainId' })
  if (!network || network.length < 1) return

  return {
    "0x1" : 'main',
    "0x3" :	'ropsten',
    "0x4" :	'rinkeby',
    "0x5" :	'goerli',
    "0x2a":	'kovan'
  }[network]
}

export const getEthBalance = async () => {
  const account = await getAccount()
  if (!account) return 

  const ethBalance = await web3.eth.getBalance(account)

  return ethBalance
}

export const getEthBalanceDeci = async () => {
  const balance = await getEthBalance()  
  if (!balance) return

  const ethDeci = web3.utils.fromWei(balance, 'ether')
  const shortEthDeci = ethDeci.length > 4 ? ethDeci.slice(0,5) : ethDeci

  return shortEthDeci
}

// nft
export const getNftUri = async () => {
  const nftContract = await getContract()
  if (!nftContract) return

  const uri = await nftContract.methods.getNFTTokenURI(BASIC_PACK_ID).call()
  return uri
}

export const getPurchasedNFTIds = async () => {
  let connectedAccount, ids
  try {
    connectedAccount = await getAccount()
    if (!connectedAccount) return
    
    const nftContract = await getContract()
    if (!nftContract) return

    ids = {
      [AUCTION_PACK_ID]: await nftContract.methods.getIDFromMap(connectedAccount, AUCTION_PACK_ID).call(),
      [BASIC_PACK_ID]: await nftContract.methods.getIDFromMap(connectedAccount, BASIC_PACK_ID).call(),
      [FREE_PACK_ID]: await nftContract.methods.getIDFromMap(connectedAccount, FREE_PACK_ID).call(),
    }
  } catch (e) {
    console.log(e)
  }

  return ids
}

export const isFreeNftOnlyAccount = async () => {
  const packIds = await getPurchasedNFTIds()

  if (
    packIds[FREE_PACK_ID].length > 0 
    && packIds[AUCTION_PACK_ID].length < 1 
    && packIds[BASIC_PACK_ID].length < 1
  ) return true

  return false
}

export const getUserNFTBalance = async () => {
  let balance
  
  try {
    const connectedAccount = await getAccount()
    if (!connectedAccount) return
    
    const nftContract = await getContract()

    if (!nftContract) return

    balance = await nftContract.methods.balanceOf(connectedAccount).call()
  } catch (e) {
    console.log(e)
  }

  return balance
}

export const buyNFT = async (packId) => {
  const connectedAccount = await getAccount()
  if (!connectedAccount) return
  
  let price
  
  try { 
    price = await getNFTPrice(CONTRACT_ADDRESS, packId)
  } catch (e) {
    console.log(e)
  }
  
  try {
    const nftContract = await getContract()

    if (!nftContract) return

    await nftContract.methods.buyNFT(packId).send({
      from: connectedAccount,
      value: price,
    })
  } catch (e) {
    console.log(e)
  }

  const attributes = await getNftJson(packId)
  return attributes
}



// auction nft

export const claimAuctionNft = async () => {
  
  const connectedAccount = await getAccount()
  if (!connectedAccount) return
  
  const nftContract = await getContract()
  if (!nftContract) return

  const price = await getNFTPrice(CONTRACT_ADDRESS, AUCTION_PACK_ID)

  const tx = {
    from: connectedAccount,
    value: price,
  }

  await nftContract.methods.buyAuctionNFT(AUCTION_PACK_ID, AUCTION_ADDRESS).send(tx)
  const attributes = await getNftJson(AUCTION_PACK_ID)
  return attributes
}


export const getAuctionNftAvailable = async () => {
  let isAvailable 
  
  try {
    const nftContract = await getContract()
    if (!nftContract) return

    isAvailable = await nftContract.methods.isNFTAvailable(AUCTION_PACK_ID).call()
  } catch (e) {
    console.log(e)
  }

  return isAvailable
}
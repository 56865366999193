import styled from "styled-components"
import { theme, device } from "../../../theme"

export const PurchaseDetails = styled.div`
  display: flex;
  flex-direction: column;

  @media ${device.tablet} {
    align-items: center;
  }
`

export const EthContainer = styled.div`
  margin-bottom: 20px;
`

export const PurchaseContainer = styled.div`
  margin-bottom: 5px;
  width: 100%;

  @media ${device.tablet} {
    width: 100%;
  }
`

export const EthCounter = styled.h1`
  margin-bottom: 0;
  font-weight: normal;
`

export const PurchaseButton = styled.button`
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 15px;
  padding: 0.5em 4em;
  color: ${theme.white};
  background-color: ${(props) => {
    if (props.loading) return 'rgba(0,0,0,0.5)'
    if (props.disabled) return 'rgba(0,0,0,0.5)'
    return theme.black
  }};
  border: 0;
  outline: none;
  cursor: ${props => props.disabled ? 'normal' : 'pointer' };
  font-family: "Space Mono", monospace;
  width: 100%;

  &:hover {
    opacity: 0.75;
  }
`

export const BidButton = styled.button`
  margin-top:10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: ${(props) => props.big ? '25px' : '15px'};
  padding: 0.5em 4em;
  color: ${theme.white};
  background-color: ${(props) => {
    if (props.loading) return 'rgba(0,0,0,0.5)'
    if (props.disabled) return 'rgba(0,0,0,0.5)'
    return theme.black
  }};
  border: 0;
  outline: none;
  cursor: ${props => props.disabled ? 'normal' : 'pointer' };
  font-family: "Space Mono", monospace;
  width: 100%;

  &:hover {
    opacity: 0.75;
  }
`

export const EMark = styled.img`
  height: 20px;
`

export const QuestionMark = styled.img`
  height: 18px;
  margin-left: 7px;
  cursor: pointer;
`

export const Purchased = styled.div`
padding: 0.5em 4em;
color: ${theme.black};
background-color: ${theme.white};
border: 1px solid ${theme.black};
margin 10px 0px;
display: flex;
align-items: center;
justify-content: center;
`
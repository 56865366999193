import { useContext, useState, useEffect } from "react"
import AppContext from "../../../../context"
import { isAuctionWinner } from "../../../../../api/auction"

import Winner from './winner'
import Loser from './loser'

const BidderInfo = ({ isOver, currentBid }) => {
    const { account } = useContext(AppContext)

    const [isWinner, setIsWinner] = useState(false)
    const isLoser = isOver && currentBid > 0

    useEffect(() => { 
        const getData = async () => {
            try {
                setIsWinner(await isAuctionWinner())
            } catch (e) {
                console.log(e)
            }
        }
        getData()

        return () => setIsWinner(false)
    }, [])

    if (!account) return null
    if (isWinner) return <Winner />
    if (isLoser) return <Loser currentBid={currentBid} />
    return null
}

export default BidderInfo
import React, { useContext, useEffect, useState } from "react"
import styled from "styled-components"
import Popup from "reactjs-popup"
import AppContext from "./context"
import { device } from "../theme"

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  @media ${device.tablet} {
    margin-top: 20px;
  }
`

const StyledPopup = styled(Popup)`
  &-content {
    max-width: 500px;
  }

  @media ${device.tablet} {
    &-content {
      width: 85%;
      padding: 10px;
    }
  }
`

const CloseButton = styled.div` 
  position: absolute;
  cursor: pointer;
  right: 0;
  margin-right: 20px;
  top: 0px;
  font-size: 50px;

  @media ${device.tablet} {
    margin-right: 10px;
  }
`


const WrongNetwork = () => {
  const [open, setOpen] = useState(false)
  const { isAllowedNetwork, network } = useContext(AppContext)

  useEffect(() => {
    if (!isAllowedNetwork) setOpen(true)
    else setOpen(false)
  }, [isAllowedNetwork])

  if (!network || network.length < 1) return null

  return (
    <StyledPopup trigger={<div></div>} modal open={open} onClose={() => setOpen(false)} closeOnDocumentClick>
      {close => (
        <>
          <CloseButton onClick={close}>
            &times;
          </CloseButton>
          <ContentContainer>
            <h1>Wrong network</h1>
            <p>
              Your wallet is currently connected to a different network (<strong>{network}</strong>).
                      Please change it to the Ethereum mainnet to continue.
                  </p>
          </ContentContainer>
        </>)}
    </StyledPopup>
  )
}

export default WrongNetwork

import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone  from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

const endDate = process.env.REACT_APP_COUNTDOWN_DATE

export const countdownDate = dayjs.tz(endDate, "MM-DD-YYYY", "America/New_York").format()
export const hasDatePassed = dayjs.tz(endDate, "MM-DD-YYYY", "America/New_York").diff(dayjs()) > 0 ? true : false
import { NavHashLink } from "react-router-hash-link"
import styled from "styled-components"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faYoutube, faApple, faSpotify, faInstagram, faTwitter } from '@fortawesome/free-brands-svg-icons'
import Poster from '../../assets/background-image2.png'

import { device } from "../../theme"

const Landing = styled.section`
  height: 100vh;
  overflow: hidden;
  background-color: #000;
  background: url(${Poster});
  background-size: cover;
  background-position: center;
`

const Video = styled.video`
  object-fit: cover;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
`

const Content = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  top: 40%;
  width: 50%;
  max-width: 450px;
  z-index: 1;

  @media ${device.tablet} {
    width: 90%;
  }
`

const MessageBox = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px 40px;
  color: #fff;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  max-width: 450px;
`

// const MessageSpan = styled.span`
//   display: block;
//   margin-top: 20px;

//   @media ${device.tablet} {
//     font-size: 0.9em;
//   }
// `
const MessageTitle = styled.h1`
  margin: 3px 0px 6px;
  font-size: 2.5em;

  & span {
    display: block;
  }

  @media ${device.tablet} {
    font-size: 1.4em;
  }
`
const Icons = styled.div`
  display: flex; 
  align-items: center;
  justify-content: center;
  margin-top: 20px;
`

const Icon = styled(FontAwesomeIcon)`
  padding: 0px 10px;
  font-size: 30px;

  &:hover {
    opacity: 0.75;
  }

  @media ${device.tablet} {
    font-size: 25px;
  }
`

const LandingSection = () => {
  return (
    <>
      <Content>
        <NavHashLink smooth to="#intro" style={{'textDecoration': 'none'}}>
          <MessageBox>
            <MessageTitle>
              DR.EV4L and the official album NFTs out now
            </MessageTitle>
          </MessageBox>
        </NavHashLink>
        <Icons>
          <a href="https://www.youtube.com/watch?v=gD96yzvoze0" target="_blank" rel="noopener noreferrer"><Icon color="white" icon={faYoutube} /></a>
          <a href="https://music.apple.com/us/album/dr-ev4l/1565866642" target="_blank" rel="noopener noreferrer"><Icon color="white" icon={faApple} /></a>
          <a href="https://open.spotify.com/album/4emCVXg7Sd9tFn0DEf8B1L?si=bCup2X9lQQigsEHPW0AaEQ" target="_blank" rel="noopener noreferrer"><Icon color="white" icon={faSpotify} /></a>
          <a href="https://www.instagram.com/youngnudy/?hl=en" target="_blank" rel="noopener noreferrer"><Icon color="white" icon={faInstagram} /></a>
          <a href="https://twitter.com/pde_youngnudy?lang=en" target="_blank" rel="noopener noreferrer"><Icon color="white" icon={faTwitter} /></a>
        </Icons>
      </Content>

      <Landing>
        <Video playsInline autoPlay loop muted>
          <source src={`${process.env.REACT_APP_CLOUDFRONT_URL}/background-video3.mp4`} type="video/mp4" />
        </Video>
      </Landing>
    </>
  )
}

export default LandingSection

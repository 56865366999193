import styled from "styled-components"
import { theme } from "../../../theme"

const StyledButton = styled.button`
  font-size: 15px;
  padding: 0.25em 1em;
  color: ${theme.white};
  background-color: rgba(0, 0, 0, 0.25);
  border: 0;
  outline: none;
  cursor: ${props => props.disabled ? 'default' :'pointer'};
  font-family: "Space Mono", monospace;


`

const Button = ({ children, onClick, disabled }) => (
  <StyledButton disabled={disabled} onClick={onClick}>{children}</StyledButton>
)

export default Button
import React from "react"
import styled from "styled-components"
import { device } from "../../../theme"
import Modal from '../../core/modal'
import Link from "../link"

const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 20px;
  margin-bottom: 20px;
  max-width: 500px;
  text-align: center;

  @media ${device.tablet} {
    margin-right: 0px;
  }
`

const Image = styled.img`
  margin-bottom: 10px;
`

const CardContainer = styled.div`
  cursor: pointer;
`

const Card = ({ image, description }) => (
    <Container>
        <Image src={image} alt={description} />
        <Link>{description}</Link>
    </Container>  
)

const PopupCard = ({ image, description, children }) => (
  <Modal trigger={<CardContainer><Card image={image} description={description}/></CardContainer>} content={children} />
)

export default PopupCard

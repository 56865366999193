export const formatAddress = (address) => {
    if (!address || address.length < 10) return address || null
    const first = address.slice(0,6)
    const last = address.slice(-3)
    return `${first}...${last}`
}

export const formatEth = (eth) => {
    if (!eth || eth.length <= 4) return eth || null
    return eth.slice(0,4)
}
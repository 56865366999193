import { useContext , useEffect, useState } from "react"
import { Loader } from "rimble-ui"
import Button from "../core/button"
import ConnectModal from "../../app/connect"
import { formatAddress, formatEth } from "../../utils"
import { getEthBalanceDeci } from "../../api"

import { useDeviceType } from "../../hooks/use-device-type"

import AppContext from '../../app/context'

  const WalletItem = () => {
    const { account } = useContext(AppContext)
    const isLaptop = useDeviceType("laptop")

    const [isLoading, setIsLoading] = useState(false)
    const [ethBalance, setEthBalance] = useState(0)

    useEffect(() => {
        const getData = async () => {
            setIsLoading(true)
            try { 
              setEthBalance(await getEthBalanceDeci())
            } catch (e) {
              console.log(e)
            }
            setIsLoading(false)
        }

        getData()
    }, [account])

    const isAccountConnected = Boolean(account && account.length > 0)

    if (isLoading) return  <Loader color="white" />
    if (isAccountConnected) return <Button disabled>{formatAddress(account)} {isLaptop && <br/>} {ethBalance && ` ${formatEth(ethBalance)} ETH`} </Button>
    return <ConnectModal trigger={<div><Button>Connect {isLaptop ? '' : 'Wallet'}</Button></div>} />
  }

  export default WalletItem
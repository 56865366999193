import React from "react"
import styled from "styled-components"
import { Carousel } from "react-responsive-carousel"
import { device } from "../../../theme"

const ItemsContainer = styled.div`
  display: flex;
  justify-content: center;
  cursor: pointer;
  max-width: 500px;
  min-width: 300px;

  @media ${device.tablet} {
    margin-bottom: 20px;
  }
`


const AuctionCarousel = () => (
  <Carousel
    showStatus={false}
    showThumbs={false}
    infiniteLoop
  >
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/auction/1_OFFICIAL_DREV4L_NFT.gif`} alt="official nft" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/auction/2_DREV4L_BackCover_NFT.gif`} alt="back cover nft" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/auction/3_BTSFootage.gif`} alt="behind the scenes footage" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/auction/5_VIP_Tickets.gif`} alt="vip tickets" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/auction/6_MerchGraphicAnimation.gif`} alt="merch graphic animation" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/auction/7_HighResArtwork.gif`} alt="high resolution artwork" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/auction/8_CertificateOfAuthenticity.gif`} alt="certificate of authenticity" />
    </div>
  </Carousel>
)

const BasicCarousel = () => (
  <Carousel
    showStatus={false}
    showThumbs={false}
    infiniteLoop
  >
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/400/1_OFFICIAL_DREV4L_NFT.gif`} alt="official nft" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/400/2_BTSFootage.gif`} alt="exclusive footage" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/400/3_HighResArtwork.gif`} alt="high res artwork" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/400/4_MerchGraphicAnimation.gif`} alt="merch graphic" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/400/5_CertificateOfAuthenticity.gif`} alt="certificate of authenticity" />
    </div>
  </Carousel>
)

const FreeCarousel = () => (
  <Carousel
    showStatus={false}
    showThumbs={false}
    infiniteLoop
  >
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/400/1_OFFICIAL_DREV4L_NFT.gif`} alt="official nft" />
    </div>
    <div>
      <img src={`${process.env.REACT_APP_CLOUDFRONT_URL}/slider/400/5_CertificateOfAuthenticity.gif`} alt="certificate of authenticity" />
    </div>
  </Carousel>
)
const Slider = ({ edition }) => {
  return (
    <ItemsContainer>
      {edition === "auction" && <AuctionCarousel/>}
      {edition === "basic" && <BasicCarousel/>}
      {edition === "free" && <FreeCarousel/>}
    </ItemsContainer>
  )
}
export default Slider

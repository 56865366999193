
import { NavHashLink } from "react-router-hash-link"
import styled from "styled-components"

import BareButton from "../core/bareButton"
import GalleryLink from './galleryLink'
import WalletItem from './walletItem'

import { theme } from "../../theme"

const NavButton = styled(BareButton)`
    color: ${props => props.white ? theme.black : theme.white};
    margin-right: 20px;
`

const DesktopNav = ({ white }) => (
    <>
        {process.env.REACT_APP_TOGGLE_NFTS && (
            <NavHashLink smooth to="/#nfts">
                <NavButton white={white}>NFTs</NavButton>
            </NavHashLink>
        )}
        <NavHashLink smooth to="/#downloads">
        <NavButton white={white}>Downloads</NavButton>
        </NavHashLink>
        <NavHashLink smooth to="/#merchandise">
        <NavButton white={white}>Merchandise</NavButton>
        </NavHashLink>
        <NavHashLink smooth to="/#aboutNfts">
        <NavButton white={white}>Info</NavButton>
        </NavHashLink>
        <WalletItem />
        <GalleryLink />
    </>
)

export default DesktopNav
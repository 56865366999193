import { useEffect, useContext, useState } from "react"
import { Loader } from "rimble-ui"
import styled from "styled-components"
import {
  buyNFT,
  getNftTypeMap
} from "../../../api"
import Modal from "../../../components/core/modal"
import ConnectModal from "../../connect"
import EMarkImage from "../../../assets/e.svg"
import QuestionMarkImage from "../../../assets/question-mark.svg"

import {
  PurchaseDetails,
  EthContainer,
  PurchaseContainer,
  EthCounter,
  PurchaseButton,
  EMark,
  QuestionMark
} from './purchaseStyles'

import AppContext from "../../context"

const PendingLoader = styled(Loader)`
  margin-right: 10px;
`

const Purchase = ({ packId }) => {
  const { isAccountConnected, isAllowedNetwork } = useContext(AppContext)
  const [purchased, setPurchased] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [price, setPrice] = useState(0)

  const getNftData = async () => {
    const nftMap = await getNftTypeMap(packId)
    if (!nftMap) return
    
    const { count, price } = nftMap

    const leftForPurchase = parseInt(count) || 0
    setPrice(price)
    setPurchased(leftForPurchase)
  }

  const purchaseProduct = async () => {
    setIsLoading(true)
    try {
      await buyNFT(packId)
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
    getNftData()
  }

  useEffect(() => {
    getNftData()
    
    return () => {
      setPrice(0)
      setPurchased(0)
    }
  }, [])

  const PurchaseNFTButton = () => {
    return <PurchaseButton disabled>Sold Out</PurchaseButton>
    if (purchased === 0) return <PurchaseButton disabled>Sold Out</PurchaseButton>
    if (!isAccountConnected || !isAllowedNetwork) return <ConnectModal trigger={<PurchaseButton>Purchase</PurchaseButton>} />
    if (isLoading) return <PurchaseButton loading={"true"}><PendingLoader color="white" /> Pending...</PurchaseButton>
    return <PurchaseButton onClick={purchaseProduct}>Purchase</PurchaseButton>
  }
  
  return (
    <PurchaseDetails>
      <EthContainer>
        <EthCounter><EMark src={EMarkImage} /> {price} ETH<Modal
        trigger={<QuestionMark src={QuestionMarkImage} />}
        content={"The final price will include gas fees which are dictated by the traffic of the Ethereum blockchain. For instance, if the Ethereum blockchain is heavily congested, the gas fees will be higher and vice versa."}
      /></EthCounter>
      </EthContainer>
      <PurchaseContainer>
        <PurchaseNFTButton />
      </PurchaseContainer>
      <br/>
      <small>* Transaction time varies based on Ethereum network congestion.  View status in MetaMask.</small>
    </PurchaseDetails>
  )
}

export default Purchase

import styled from "styled-components"
import Mailchimp from 'react-mailchimp-form'
import Section from "../../components/core/section"
import { device } from "../../theme"

const Content = styled.div`
  max-width: 900px;
  line-height: 22px;
  text-align: justify;
  text-align-last: left;
  & div { 
    text-align: center;
    text-align-last: center;

  }
}
`

const MailChimpContainer = styled.div`
  margin-bottom: 50px;
  display: flex;
  justify-content: center;
`

const StyledMailchimp = styled(Mailchimp)`
  width:100%;
  text-align: center;

  & input {
    font-size: 1em;
    padding: 1em 1.5em;
    border-radius: 0px;
    border: 1px solid #000;
    margin-right: 10px;
    width: 50%;

    @media ${device.tablet} {
      width: 40%;
    }
  }
  & button {
    font-size: 15px;
    padding: 1em 1.5em;
    color: #fff;
    background-color: #000;
    border: 0;
    outline: none;
    font-family: "Space Mono", monospace;
    cursor: pointer;

    &:hover {
      opacity: 0.75;
    }
  }
`

const Intro = () => (
  <Section title={"DR. EV4L out now"} id={"intro"}>
    <MailChimpContainer>
    <StyledMailchimp
        action={process.env.REACT_APP_MAILCHIMP_URL}
        fields={[
          {
            name: 'EMAIL',
            placeholder: 'Enter email for updates',
            type: 'email',
            required: true
          }
        ]}
        messages = {
          {
            sending: "Sending...",
            success: "Thank you for subscribing!",
            error: "An unexpected internal error has occurred.",
            empty: "You must write an e-mail.",
            duplicate: "Too many subscribe attempts for this email address",
            button: "Subscribe"
          }
        }
        />
    </MailChimpContainer>
    <Content>
      Reigning from East Atlanta, ZONE6’s very own Young Nudy has made a name for himself over the last decade starting in the streets and now globally in the music world. The Paradise East rapper jumped off in 2016 with the Slimeball mixtape. Going on to work with artists such as 21 Savage, Playboi Carti, J Cole and producers like Metro Boomin and Pi’erre Bourne, Young Nudy has been turning heads in the industry. He instantly became a fan favorite for his Slimeball persona and one-of-a-kind aesthetic. After last year’s release Anyways made big waves, Young Nudy’s next masterpiece is here and he impresses once again. 
      <br/><br/>
      <div><strong>Introducing, DR.EV4L.</strong></div>
      <br/>
      DR.EV4L is Young Nudy’s best work to date and his continued evolution is clear from start to end. 13 tracks, no fillers. Featuring 21 Savage, Lil Uzi Vert, G Herbo and producers Coupe, 20 Rocket, Mojo and Bavier. To celebrate the newest album, Young Nudy will be releasing his first official NFT. These NFTs are verified by Sony/RCA and Young Nudy himself. Unique, limited and fully loaded with exclusive content for fans to enjoy forever. Users who buy and hold these NFTs will be rewarded with both immediate and future rewards. Young Nudy loves his fans so it’s only right he uses NFTs to take the artist-fan experience to the next level.
    </Content>
  </Section>
)

export default Intro

import { useState } from 'react'
import styled from 'styled-components'
import { Loader } from 'rimble-ui'
import { theme } from '../../../../../theme'
import Link from "../../../../../components/core/link"
import { withdrawFunds, getCurrentBid } from "../../../../../api/auction"

const PurchaseInformation = styled.div`
  color: ${theme.secondary};
  text-align:center;
`

const InlineLoader = styled(Loader)`
  display: inline-block;
`


const Loser = ({ currentBid }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [bid , setBid] = useState(currentBid)

  const handleWithdraw = async () => {
    setIsLoading(true)
    try {
      await withdrawFunds()
      setBid(await getCurrentBid())
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  const Withdraw = () => {
    if (isLoading) return <div><InlineLoader /> Pending....</div>
    if (bid > 0) return <Link onClick={handleWithdraw}>Click here to withdraw your funds.</Link>

    return <div>You have withdrawn your funds.</div>
  }

  return (
    <PurchaseInformation>
        <h1>Sorry, you did not win this auction.</h1>
        <Withdraw />
        <br/> <br/>
        If you have any issues please email<br/>
        <strong>info@argon.industries</strong>
    </PurchaseInformation>
  )
}

export default Loser
import styled from "styled-components"
import Section from "../../components/core/section"
import { useDeviceType } from "../../hooks/use-device-type"
import { device } from "../../theme"

const MERCH_STORE_URL = process.env.REACT_APP_MERCH_STORE_URL

const StyledSection = styled(Section)`
  position: relative;
`

const Overlay = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;

  @media ${device.tablet} {
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 1;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    opacity: 1;
  }
`

const OverlayTitle = styled.div`
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  position: absolute;

  @media ${device.tablet} {
    font-size: 30px;
  }
`

const Image = styled.img`
  width: 100%;
  max-width: 1200px;
`

const Merchandise = () => {
  const isTablet = useDeviceType("tablet")
  return (
    <StyledSection title={isTablet ? "" : "Merchandise"} id="merchandise">
      <Image src={`${process.env.REACT_APP_CLOUDFRONT_URL}/merchandise.png`} alt="webstore merchandise" />
      <a href={MERCH_STORE_URL} target="_blank" rel="noopener noreferrer">
        <Overlay>
          <OverlayTitle>Slimeball.World</OverlayTitle>
        </Overlay>
      </a>
    </StyledSection>
  )
}

export default Merchandise

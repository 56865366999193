import { useState, useEffect } from "react"
import styled from 'styled-components'
import BigNumber from 'bignumber.js';

import {
  Form,
  Input,
  Loader
} from "rimble-ui";

import { 
  getHighestBid, 
  getBidIncrement, 
  getEthereumPrice,
} from "../../../../../api/auction"

import { device } from "../../../../../theme"

import { getEthBalanceDeci } from "../../../../../api"

import { BidButton } from '../../purchaseStyles'


const BidDetails = styled.div`
  margin: 40px 0;
`
const BidDetail = styled.div`
  margin: 10px 40px 0; 
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    margin: 10px 0px 0; 
  }
`

const StyledForm = styled(Form)`
  text-align: center;
`

const InfoText = styled.div`
  color: #999999;
  margin-top: 10px;
`

const InlineLoader = styled(Loader)`
  display: inline-block;
`

const Bid = ({ handleBid, onChange, ethBid, currentBid }) => {
    const [isHighestBidLoading, setIsHighestBidLoading] = useState(false)
    const [highestBid, setHighestBid] = useState(0)
    const [ethPrice, setEthPrice] = useState(0)
    const [bidIncrement, setBidIncrement] = useState(0)
    const [ethBalance, setEthBalance] = useState(0)

    useEffect(() => { 
      const getData = async () => {
        setIsHighestBidLoading(true)
        try {
          setBidIncrement(await getBidIncrement())
          setHighestBid(await getHighestBid())
          setEthBalance(await getEthBalanceDeci())
          setEthPrice(await getEthereumPrice())
        } catch (e) {
          console.log(e)
        }
        setIsHighestBidLoading(false)
      }
      getData()

      return () => {
        setBidIncrement(0)
        setHighestBid(0)
        setIsHighestBidLoading(false)
      }
    }, [])

    const usdConversion = parseFloat((ethPrice * ethBid) || 0).toFixed(2)
    const nextBidRequired = BigNumber(highestBid).plus(bidIncrement).toNumber()
    const isValid = () => {
      const isCurrentBidGreaterThanHighest = BigNumber(ethBid).plus(currentBid).toNumber() >= BigNumber(highestBid).plus(bidIncrement).toNumber()
      const isBidGreaterThanBalance = BigNumber(ethBid).minus(highestBid) <= BigNumber(ethBalance)

      if (BigNumber(ethBid).minus(highestBid) < 0) return false
      if (isCurrentBidGreaterThanHighest && isBidGreaterThanBalance) return true

      return false
    }

    // const getActualBid = () => {
    //   if (isValid()) return BigNumber(ethBid).minus(currentBid).toString()
    // }

     return (
      <StyledForm>
      <h1>Raise your bid</h1>
        <BidDetail>Highest bid: <strong>{isHighestBidLoading ? <InlineLoader /> : `${highestBid}`} ETH</strong> </BidDetail>
        <BidDetail>Next bid required: <strong>{nextBidRequired} ETH</strong></BidDetail>
       <br/><br/>
      <div>
        <Input 
          value={ethBid}
          onChange={(e) => onChange(e.target.value)}
          placeholder={0} 
          /> <strong>ETH</strong>
        <InfoText>usd equivalent: <strong>${usdConversion}</strong></InfoText>
      <BidDetails>
        <BidDetail>Your current bid: <strong>{isHighestBidLoading ? <InlineLoader /> : `${currentBid}`} ETH</strong></BidDetail>
        <BidDetail>Your wallet balance: <strong>{ethBalance} ETH</strong></BidDetail>
      </BidDetails>
      </div>
      <BidButton big onClick={handleBid} disabled={!isValid()} >Raise Bid</BidButton>
      <InfoText>Once submitted, bids cannot be reversed.</InfoText>
      </StyledForm>
    )
  }

export default Bid
import React, { useState } from "react"
import styled from "styled-components"
import ConnectModal from "../../connect"
import { theme, device } from "../../../theme"
import Modal from "../../../components/core/modal"
import Link from "../../../components/core/link"
import Unlocked from "../../../assets/unlocked.svg"

const SliderControl = styled.div`
  padding: 20px 0;
`

const SliderNumbers = styled.div`
  display: flex;
  justify-content: space-evenly;
`

const UnlockedImage = styled.img`
  height: 20px;
`

const NumberContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`
const NumberTitle = styled.div`
  color: ${(props) => (props.active ? theme.secondary : theme.white)};
  padding-bottom: 10px;

  @media ${device.tablet} {
    font-size: 11px;
  }
`

const SmallCircle = styled.div`
  background: ${(props) => (props.active ? theme.secondary : theme.primary)};
  border-radius: 0.8em;
  display: inline-block;
  margin-right: 5px;
  width: 0.5em;
  height: 0.5em;
`

const Line = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${theme.primary};
  margin-top: -5px;
`

const Item = styled.div`
  display: flex;
  padding: 50px 200px;

  @media ${device.laptopL} {
    padding: 50px 50px;
  }

  @media ${device.laptop} {
    padding: 50px 20px;
  }

  @media ${device.tablet} {
    flex-direction: column;
    padding: 50px 0px;
  }
`

const Title = styled.h1`
  margin-top: 0px;
  margin-right: 20px;
`

const ContentItem = styled.div`
  margin-right: 20px;
  line-height: 25px;

  @media ${device.tablet} {
    margin-right: 0px;
    margin-bottom: 20px;
  }
`

const ItemsContainer = styled.div`
  padding: 50px 100px;

  @media ${device.tablet} {
    padding: 40px 20px;
  }
`

const SecurityPopup = () => (
  <Modal
    trigger={
      <Link secondary>
        passphrases, private keys and passwords are safely stored
      </Link>
    }
    content={"When you have a wallet address made on the blockchain, you will receive a private key/passphrase. You must always have this information when you want to access any of the cryptocurrency that you own. We recommend that you write these privacy passwords down on paper or print it out for safe keeping. This is called your paper wallet. Print multiples and store them in secure places that only you will remember."}
  />
)

const Slider = () => {
  const [slide, setSlide] = useState(1)

  const SliderNumber = ({ index, title }) => (
    <NumberContainer
      onClick={() => setSlide(index)}
      onMouseEnter={() => setSlide(index)}
    >
      <NumberTitle active={index === slide}>{title}</NumberTitle>
      <SmallCircle active={index === slide} />
    </NumberContainer>
  )

  return (
    <>
      <SliderControl>
        <SliderNumbers>
          {[{key: 1, title : 'SET-UP'}, {key: 2, title : 'DEPOSIT'}, {key: 3, title : 'PURCHASE'}, {key: 4, title : 'AUCTION'}, {key: 5, title : 'UNLOCK'}].map((i) => (
            <SliderNumber key={i.key} index={i.key} title={i.title} />
          ))}
        </SliderNumbers>
        <Line />
      </SliderControl>

      <ItemsContainer>
        {slide === 1 && (
          <Item>
            <Title>Set-up and Connect Wallet</Title>
            <ContentItem>
              Choose an existing wallet or{" "}
              <Link
                href={"https://metamask.io/download"}
                target="_blank"
                rel="noopener noreferrer"
                secondary
              >
                create one
              </Link>
              . After you have gone through all necessary steps,{" "}
              <ConnectModal
                trigger={<Link secondary>connect your wallet</Link>}
              />
              .
            </ContentItem>
          </Item>
        )}
        {slide === 2 && (
          <Item>
            <Title>Deposit and Purchase Ethereum</Title>
            <ContentItem>
              If you already have existing crypto currency, send it into your
              wallet as your specific wallet instructs. If you don’t have any
              crypto currency, you must purchase some. You can do this through
              many wallets and exchanges. Once you have purchased Ethereum, you
              can now purchase the NFT.
              <br />
              <br />* The final price will include gas fees which are dictated
              by the traffic of the Ethereum blockchain. For instance, if the
              Ethereum blockchain is heavily congested, the gas fees will be
              higher and vice versa.
            </ContentItem>
          </Item>
        )}
        {slide === 3 && (
          <Item>
            <Title>Purchase and Store NFTs</Title>
            <ContentItem>
              Once your wallet is ready to go and your <SecurityPopup />, you’re now ready to purchase your NFTs. NFTs are stored and viewable on the blockchain through the transaction ID.
            </ContentItem>
          </Item>
        )}
        {slide === 4 && (
          <Item>
            <Title>Auction Details</Title>
            <ContentItem>
              A starting price is set for the auction. Buyers must place bids that are higher than whatever the current price is at the time of their bid. To see the current price of the auction, you must connect your wallet. 
              <br />
              <br />
              After the first bid is placed, a 4 day countdown for the auction starts. When the countdown is finished, the auction is over. Once the auction is over, whoever has the highest bid has won the NFT.
              <br />
              <br />
              When you place a bid, you must exceed or meet the current price. You can track all bids by clicking “View Bid History” on the listing (only viewable if your wallet is connected).
            </ContentItem>
          </Item>
        )}
        {slide === 5 && (
          <Item>
            <Title>Accessing Unlockable Properties</Title>
            <ContentItem>
            After you’ve purchased an NFT from our platform, the Unlocked Gallery will be accessible to you. Here, you can view your NFT unlockables. Find the <UnlockedImage src={Unlocked} alt="unlockedNfts" /> Unlocked Gallery icon at the top right of the website. You are required to have purchased an NFT and also have your wallet connected to access the Gallery.
            <br/><br/>
            To add the NFT to your wallet, this is done most simply through the <Link
                href={"https://metamask.io/download"}
                target="_blank"
                rel="noopener noreferrer"
                secondary
              >MetaMask mobile app</Link>. Make sure you are viewing the exact wallet address that was used to purchase the NFT. In the mobile app, select Collectibles then select <strong>Add Collectibles</strong>. Enter the NFT address and collectible ID. Your NFT will then appear under the <strong>Collectibles</strong> tab on your MetaMask wallet.
            </ContentItem>
          </Item>
        )}
      </ItemsContainer>
    </>
  )
}
export default Slider

import { createContext, useContext, useEffect, useRef, useState } from "react"
import json2mq from "json2mq"

const DeviceTypeContext = createContext({})
export const DeviceTypeProvider = DeviceTypeContext.Provider

const size = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
}

const defaultBreakpoints = {
  mobileS: { maxWidth: size.mobileS },
  mobileM: { maxWidth: size.mobileM },
  mobileL: { maxWidth: size.mobileL },
  tablet: { maxWidth: size.tablet },
  laptop: { maxWidth: size.laptop },
  laptopL: { maxWidth: size.laptopL },
  desktop: { maxWidth: size.desktop },
  desktopL: { maxWidth: size.desktop },
}

const getMediaQueryList = (query) => {
  if (typeof window === "undefined") return null
  return window.matchMedia(json2mq(query))
}

export const useDeviceType = (deviceType) => {
  const { breakpoints = defaultBreakpoints } = useContext(DeviceTypeContext)
  const mediaQueryListRef = useRef()
  const query = breakpoints[deviceType]

  mediaQueryListRef.current = getMediaQueryList(query)

  const [matches, setMatches] = useState(
    mediaQueryListRef.current ? mediaQueryListRef.current.matches : false,
  )

  useEffect(() => {
    mediaQueryListRef.current =
      mediaQueryListRef.mediaQueryList || getMediaQueryList(query)
    let active = true

    const listener = () => {
      if (!active) return

      if (mediaQueryListRef.current.matches) {
        setMatches(true)
      } else {
        setMatches(false)
      }
    }

    mediaQueryListRef.current.addListener(listener)
    setMatches(mediaQueryListRef.current.matches)

    return () => {
      active = false
      mediaQueryListRef.current.removeListener(listener)
    }
  }, [query])

  return matches
}

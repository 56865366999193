
import styled from 'styled-components'
import { Loader } from "rimble-ui"
import { formatAddress } from "../../../../../utils"
import { device } from "../../../../../theme"

import HistoryItems from './items'

const Title = styled.h1`
  text-align: center;
`

const AuctionItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  border: 1px solid #999999;
  margin-bottom: 10px;

  @media ${device.tablet} {
    padding: 10px;
  }
`

const HistoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%
`

const Items = styled.div`
  max-height: 200px;
  width: 100%;
  overflow-y: scroll;
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const InlineLoader = styled(Loader)`
  display: inline-block;
`

const History = ({ owner, isOwnerLoading }) =>  (
    <>
        <Title>Auction Info</Title>
        <AuctionItemContainer>
            Artist: <strong>Young Nudy</strong>
        </AuctionItemContainer>
        <AuctionItemContainer>
            Owner: <strong>{isOwnerLoading ? <InlineLoader/> : formatAddress(owner)}</strong>
        </AuctionItemContainer>
        <AuctionItemContainer>
            <HistoryContainer>
                Bid History
                <Items>
                    <HistoryItems />
                </Items>
            </HistoryContainer>
        </AuctionItemContainer>
    </>
)

export default History
import styled from "styled-components"
import Popup from "reactjs-popup"

import { device } from "../../theme"

import MenuButton from "../../assets/menu.svg"
import MenuButtonBlack from "../../assets/menu-black.svg"

import BareButton from "../core/bareButton"
import OverlayMenu from './overlayMenu'
import WalletItem from "./walletItem"

const StyledPopup = styled(Popup)`
  &-content {
    height: 100%;
    width: 100%;
  }
`

const MobileNavContainer = styled.div`
  display: flex;
  align-items: center;

  & Button {
    margin-right: 10px;

    @media ${device.tablet} {
      font-size: 10px;
    }   
  }
`

const HamburgerMenu = styled.img`
  height: 25px;
  width: 45px;
`

const MobileNav = ({ white }) => (
    <MobileNavContainer>
        <WalletItem />
        <StyledPopup
          trigger={
            <BareButton>
              <HamburgerMenu src={white ? MenuButton : MenuButtonBlack} alt="menu button" />
            </BareButton>
          }
          modal
        >
          {(close) => (
            <OverlayMenu close={close} />
          )}
        </StyledPopup>
    </MobileNavContainer>
)

export default MobileNav

import { useEffect, useState } from 'react'
import { Redirect, Route } from "react-router-dom"
import { getUserNFTBalance } from '../api'
import { isAuctionWinner } from '../api/auction'

const PurchaseRoute = ({ children, ...rest }) => {
    const [isLoading, setLoading] = useState(true);
    const [isAccessable, setIsAccessable] = useState(false)

    useEffect(() => {
        const getData = async () => {
            const balance = await getUserNFTBalance()
            
            if (Boolean(balance < 1)) {
              setIsAccessable(await isAuctionWinner())
              return setLoading(false)
            }

            setIsAccessable(Boolean(balance > 0))
            setLoading(false);
        }
        getData()
    }, [])

    return (
      <Route
        {...rest}
        render={({ location }) => isAccessable ? (
            children
          ) : isLoading ? (<></>) : (
            <Redirect to={{pathname: "/", state: { from: location }}} />
          )
        }
      />
    );
  }

export default PurchaseRoute
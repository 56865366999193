import { useState, useEffect } from "react"
import Modal from "../../../../components/core/modal"
import { getOwner, isAuctionOver, getCurrentBid, getHighestBid } from "../../../../api/auction"
import HighestBid from './HighestBid'
import BidderInfo from './BidderInfo'
import History from './History'
import ActionButton from './ActionButton'

import {
  PurchaseDetails,
  PurchaseContainer,
  BidButton,
} from '../purchaseStyles'

const Auction = () => {
  const [isOver, setIsAuctionOver] = useState(false)
  const [owner, setOwner] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [currentBid, setCurrentBid] = useState(0)
  const [isHighestBidLoading, setIsHighestBidLoading] = useState(false)
  const [highestBid, setHighestBid] = useState(0)

  useEffect(() => { 
    const getData = async () => {
      setIsLoading(true)
      
      try {
        setIsAuctionOver(await isAuctionOver())
        setCurrentBid(await getCurrentBid())
        setOwner(await getOwner())
        
      } catch (e) {
        console.log(e)
      }

      setIsLoading(false)
    }
    getData()

    return () => {
      setIsAuctionOver(false)
      setIsLoading(false)
      setOwner('')
    }
  }, [])

  useEffect(() => updateHighestBid(), [highestBid])

  const updateHighestBid = async () => {
      setIsHighestBidLoading(true)
      
      try {
        setHighestBid(await getHighestBid())
      } catch (e) {
        console.log(e)
      }

      setIsHighestBidLoading(false)
  }
  
  return (
    <PurchaseDetails>
      <HighestBid highestBid={highestBid} isLoading={isHighestBidLoading} />
      <PurchaseContainer>
        <ActionButton isOver={isOver} currentBid={currentBid} onHighestBidUpdate={updateHighestBid}  />
        <Modal
          trigger={<BidButton>View Bid History</BidButton>}
          content={<History owner={owner} isLoading={isLoading}/>}
        />
      </PurchaseContainer>
      <BidderInfo isOver={isOver} owner={owner} currentBid={currentBid} />
      <br/>
      <small>* Transaction time varies based on Ethereum network congestion. View status in MetaMask.</small>
    </PurchaseDetails>
  )
}

export default Auction

import styled from "styled-components"
import { device } from "../../../theme"
import Slider from "./slider"
import Purchase from "./purchase"
import Auction from "./auction"

import {
  PurchaseButton
} from './purchaseStyles'

const TOGGLE_PURCHASING = process.env.REACT_APP_TOGGLE_PURCHASING

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    flex-direction: column-reverse;
  }
`

const NftDetails = styled.div`
  max-width: 440px;
  margin-right: 40px;
  margin-bottom: 100px;

  @media ${device.tablet} {
    margin-right: 0px;
    margin-bottom: 40px;
  }
`

const NftCopy = styled.div`
  line-height: 20px;
`

const NftTitle = styled.h1`
  margin-top: 0;
`

const NftItems = styled.div``

const titleHeader = "DR.EV4L"


const ActionButton = ({ auction, packId }) => {
  if (!TOGGLE_PURCHASING) return <PurchaseButton disabled>Dropping @ 12PM EST on 05/18</PurchaseButton>
  if (auction) return <Auction />

  return (
    <Purchase packId={packId} />
  )
}

const Item = ({ title, auction, edition, packId, children }) => (
  <CardsContainer>
    <NftDetails>
      <NftTitle>
        {titleHeader}
        <br />
        {title}
      </NftTitle>
      <NftCopy>{children}</NftCopy>
      <ActionButton auction={auction} packId={packId} />
    </NftDetails>
    <NftItems>
      <Slider edition={edition} />
    </NftItems>
  </CardsContainer>
)

export default Item

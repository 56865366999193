import React from "react"
import ReactDOM from "react-dom"
import "./index.css"
import App from "./app"
import reportWebVitals from "./reportWebVitals"
import { BrowserRouter } from "react-router-dom"
import smoothScrollPolyfill from "smoothscroll-polyfill"
import "reactjs-popup/dist/index.css"
import "react-responsive-carousel/lib/styles/carousel.min.css"

smoothScrollPolyfill.polyfill()

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById("root"),
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

import { useContext, useState } from "react"
import AppContext from "../../../../context"
import { Loader } from "rimble-ui"
import * as yup from 'yup';
import styled from 'styled-components'

import Modal from "../../../../../components/core/modal"
import ConnectModal from "../../../../connect"
import { PurchaseButton } from '../../purchaseStyles'

import {  placeBid } from "../../../../../api/auction"

import Bid from '../Bid'

const PendingLoader = styled(Loader)`
  margin-right: 10px;
`

const ActionButton = ({ isOver, currentBid, onHighestBidUpdate }) => {
    const { isAccountConnected, isAllowedNetwork } = useContext(AppContext)

    const [isLoading, setIsLoading] = useState(false)
    const [ethBid, setEthBid] = useState()

    const handleBid = async () => {
      setIsLoading(true)
      try {
          await placeBid(ethBid)
      } catch (e) {
          console.log(e)
        }
      onHighestBidUpdate()
      setIsLoading(false)
    }

    const handleBidOnChange = async (value) => {
      const isNumber = yup.number().nullable().transform((value, originalValue) => (String(originalValue).trim() === '' ? null : value))
      const isValueNumber = await isNumber.isValid(value)

      if (!isValueNumber) return null
      setEthBid(value)
    }
    return <PurchaseButton disabled>Auction Ended</PurchaseButton>
    // if (!isAccountConnected || !isAllowedNetwork) return <ConnectModal trigger={<PurchaseButton>Place Bid</PurchaseButton>} />
    // if (isLoading) return <PurchaseButton loading><PendingLoader color="white" /> Pending...</PurchaseButton>
    // if (isOver) return <PurchaseButton disabled>Auction Ended</PurchaseButton>

    // return ( 
    //   <Modal
    //     trigger={<PurchaseButton>{currentBid > 0 ? 'Raise Bid' : 'Place Bid'}</PurchaseButton>}
    //     content={
    //       <Bid 
    //         handleBid={handleBid} 
    //         onChange={handleBidOnChange}
    //         ethBid={ethBid}
    //         currentBid={currentBid}
    //       />
    //     }
    //   />
    // )
  }

export default ActionButton
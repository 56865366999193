import styled from "styled-components"
import { theme } from "../../../theme"

const Link = styled.a`
  color: ${(props) => (props.secondary ? theme.white : theme.black)};
  text-decoration: underline;
  cursor: pointer;
`

export default Link

import styled from "styled-components"
import { NavHashLink } from "react-router-hash-link"

import { theme } from "../../theme"

import BareLink from "../core/link"
import BareButton from "../core/bareButton"

import GalleryLink from './galleryLink'

const OverlayContainer = styled.div`
  background-color: ${theme.white};
`

const OverlayItems = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin: 100px 20px 0px;
`

const OverlayLink = styled(NavHashLink)`
    font-size: 40px;
    margin-bottom 10px;
`

const OverlayButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
  margin-right: 10px;
  font-size: 60px;
`

const OverlayMenu = ({ close }) => (
    <OverlayContainer>
      <OverlayButtonContainer>
        <BareButton onClick={close}>
            &times;
        </BareButton>
      </OverlayButtonContainer>
      <OverlayItems>
        {process.env.REACT_APP_TOGGLE_NFTS && (
          <OverlayLink onClick={close} smooth to="/#nfts">
            <BareLink>NFTs</BareLink>
          </OverlayLink>
        )}
        <OverlayLink onClick={close} smooth to="/#downloads">
          <BareLink>Downloads</BareLink>
        </OverlayLink>
        <OverlayLink onClick={close} smooth to="/#merchandise">
          <BareLink>Merchandise</BareLink>
        </OverlayLink>
        <OverlayLink onClick={close} smooth to="/#aboutNfts">
          <BareLink>Info</BareLink>
        </OverlayLink>
        <BareLink>
        <GalleryLink onClick={close} />
        </BareLink>
      </OverlayItems>
    </OverlayContainer>
  )

export default OverlayMenu
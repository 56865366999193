import styled from 'styled-components'
import { Loader } from "rimble-ui"
import Modal from "../../../../../components/core/modal"
import EMarkImage from "../../../../../assets/e.svg"
import QuestionMarkImage from "../../../../../assets/question-mark.svg"

import {
    EthContainer,
    EthCounter,
    EMark,
    QuestionMark
} from '../../purchaseStyles'

const InlineLoader = styled(Loader)`
  display: inline-block;
`

const HighestBid = ({ highestBid, isLoading }) => {
  return (
  <EthContainer>
      <EthCounter>
          <div><small>Highest Bid:</small></div>
          <EMark src={EMarkImage} /> {isLoading ? <InlineLoader /> : `${1.1} ETH`}<Modal
        trigger={<QuestionMark src={QuestionMarkImage} />}
        content={"Bids will include gas fees which are dictated by the traffic of the Ethereum blockchain. For instance, if the Ethereum blockchain is heavily congested, the gas fees will be higher and vice versa."}
      />
      </EthCounter>
  </EthContainer>
)}

export default HighestBid
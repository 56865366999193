import styled from "styled-components"
import { Link } from "react-router-dom"

import { device } from "../../theme"
import { useDeviceType } from "../../hooks/use-device-type"

import Logo from "../../assets/logo-white.gif"
import LogoBlack from "../../assets/logo-black.gif"

import MobileNav from './mobileNav'
import DesktopNav from './desktopNav'

const Container = styled.div`
  position: ${(props) => (props.white ? "relative" : "absolute")};
  width: 100%;
  z-index: 10;
`
const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px 100px;
  align-items: center;

  @media ${device.tablet} {
    padding: 20px 5px;
  }
`

const LogoImage = styled.img`
  height: 55px;
`

const MenuItems = styled.div`
  display: flex;
  align-items: center;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
  }
`

const Items = ({ white }) => {
  const isLaptop = useDeviceType("laptop")

  if (isLaptop) return <MobileNav white={white} />
  return <DesktopNav white={white} />
}

const Navbar = ({ white }) => (
  <Container white={white}>
    <Content>
      <Link to="/">
        <LogoImage src={white ? LogoBlack : Logo} alt="Young Nudy" />
      </Link>
      <MenuItems>
        <Items white={white} />
      </MenuItems>
    </Content>
  </Container>
)

export default Navbar

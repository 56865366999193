import React, { useState, useContext } from "react"
import { MetaMaskButton } from "rimble-ui"
import styled from "styled-components"
import Popup from "reactjs-popup"
import { Loader } from "rimble-ui"
import { useDeviceType } from "../hooks/use-device-type"
import AppContext from "./context"
import WrongNetwork from './wrongNetwork'

const { ethereum } = window

const METAMASK_DEEPLINK_URL = process.env.REACT_APP_METAMASK_DEEPLINK

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
`

const StyledPopup = styled(Popup)`
  &-content {
    max-width: 500px;
    padding: 40px;
  }

  &-content {
    max-width: 500px;
    padding: 40px;
  }
`

const CloseButton = styled.div` 
  position: absolute;
  cursor: pointer;
  right: 0;
  margin-right: 20px;
  top: 0px;
  font-size: 50px;
`

const Small = styled.small`
  margin-top: 10px;
`

const isMetaMaskInstalled = () => {
  return Boolean(ethereum && ethereum.isMetaMask)
}

const Connect = () => {
  const [isLoading, setIsLoading] = useState(false)
  const onClickConnect = async () => {
    try {
      setIsLoading(true)
      await ethereum.request({ method: "eth_requestAccounts" })
      setIsLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <ContentContainer>
      <h1>Connect your wallet.</h1>
      {isLoading ? (
        <Loader color="black" />
      ) : (
        <div onClick={onClickConnect}>
          <MetaMaskButton>Connect with MetaMask</MetaMaskButton>
        </div>
      )}
      <Small>To purchase NFTs please connect your account.</Small>
    </ContentContainer>
  )
}

const InstallMetaMask = () => (
  <ContentContainer>
    <h1>Install Metamask</h1>
    <p>
      MetaMask is a browser extension that will let you use our blockchain
      features in this browser. It may take you a few minutes to set up your
      MetaMask account.
    </p>
    <a
      href="https://metamask.io/download"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MetaMaskButton>Install MetaMask</MetaMaskButton>
    </a>
    <Small>
      Rather use your phone? You can use this app in mobile browser wallets like
      MetaMask Mobile.
    </Small>
  </ContentContainer>
)

const AlreadyConnected = () => (
  <ContentContainer>
    <h1>Your wallet is currently connected.</h1>
  </ContentContainer>
)

const MobileConnect = () => (
  <ContentContainer>
    <h1>Connect Metamask Mobile</h1>
    <p>
      To purchase NFTs on mobile, please download the MetaMask app and open this
      webapp in the wallet browser.
    </p>
    <a
      href="https://metamask.io/download"
      target="_blank"
      rel="noopener noreferrer"
    >
      <MetaMaskButton>Install MetaMask</MetaMaskButton>
    </a>
    <Small>Already Installed?</Small>
    <Small>
      <a href={METAMASK_DEEPLINK_URL}>open this site in the wallet browser</a>
    </Small>
  </ContentContainer>
)

const ModalContent = () => {
  const { account, isAllowedNetwork } = useContext(AppContext)
  const isTablet = useDeviceType("tablet")
  
  if (isTablet && !isMetaMaskInstalled()) return <MobileConnect />
  if (!isMetaMaskInstalled()) return <InstallMetaMask />
  if (!account) return <Connect />
  if (!isAllowedNetwork) return <WrongNetwork />
  return <AlreadyConnected account={account} />
}

const ConnectModal = ({ trigger }) => (
  <StyledPopup trigger={trigger} modal>
    {close => (
      <>
        <CloseButton onClick={close}>
              &times;
        </CloseButton>
        <ModalContent />
      </>
    )}
  </StyledPopup>
)

export default ConnectModal

import React from "react"
import Popup from "reactjs-popup"
import styled from "styled-components"

import { device } from "../../../theme"


const StyledPopup = styled(Popup)`
  &-content {
    max-width: 500px;
    padding: 40px;
  }

  @media ${device.tablet} {
    &-content {
      width: 90%;
      padding: 0px;
    }
  }

`


const PopupContent = styled.div`
  padding: 20px;
`
const PopupHeader = styled.div`
  margin-bottom: 10px;
  max-width: 90%;
`

const CloseButton = styled.div` 
  position: absolute;
  cursor: pointer;
  right: 0;
  margin-right: 20px;
  top: 0px;
  font-size: 50px;
`

const Modal = ({ trigger, title, content }) => (
  <StyledPopup trigger={trigger} modal>
    {close => (
      <PopupContent>
        <CloseButton onClick={close}>
          &times;
        </CloseButton>
        <PopupHeader>
          <strong>{title}</strong>
        </PopupHeader>
        {content}
      </PopupContent>
    )}
  </StyledPopup>
)

export default Modal

import { web3 } from "./web3"
import { getAccount } from './index'
import BigNumber from 'bignumber.js';

import auctionRoom from '../contracts/artifacts/contracts/AuctionRoom.json'
import auction from "../contracts/artifacts/contracts/Auction.json"

const AUCTION_ADDRESS = process.env.REACT_APP_ARGON_AUCTION_ADDRESS
const AUCTION_ROOM_ADDRESS = process.env.REACT_APP_ARGON_AUCTION_ROOM_ADDRESS

export const getAuction = async () => {
  let nftContract

  try {
    nftContract = new web3.eth.Contract(auction.abi, AUCTION_ADDRESS)
  } catch (e) {
    console.log(e)
  }
  return nftContract
}

export const getAuctionRoom = async () => {
  let nftContract;

  try {
    nftContract = new web3.eth.Contract(auctionRoom.abi, AUCTION_ROOM_ADDRESS)
  } catch (e) {
    console.log(e)
  }
  return nftContract
}

export const isAuctionCanceled = async () => {
  const auction = await getAuction()

  if (!auction) return

  const isCanceled = await auction.methods.canceled().call()
  return isCanceled
}


export const isAuctionOver = async () => {
  let auction, currentBlock, endBlock

   auction = await getAuction()

   if (!auction) return

   currentBlock = await web3.eth.getBlockNumber()
   endBlock = await auction.methods.endBlock().call()

  if (await isAuctionCanceled()) return true
  
  return Boolean(parseInt(currentBlock) >= parseInt(endBlock))
}

export const getHighestBid = async () => {
  const auction = await getAuction()

  if (!auction) return

  const highestBid = await auction.methods.getHighestBid().call()

  return web3.utils.fromWei(highestBid.toString())
}

export const getOwner = async () => {
  const auction = await getAuction()

  if (!auction) return

  const owner = await auction.methods.owner().call()

  return owner
}

export const getHighestBidder = async () => {
  const auction = await getAuction()

  if (!auction) return

  const highestBidder = await auction.methods.highestBidder().call()

  return highestBidder
}

export const withdrawFunds = async () => {
  const auction = await getAuction()

  if (!auction) return

  const connectedAccount = await getAccount()

  if (!connectedAccount) return

  await auction.methods.withdraw().send({
    from: connectedAccount
  })
}

export const getCurrentBid = async() => {
  const connectedAccount = await getAccount()
  
  if (!connectedAccount) return
  
  const auction = await getAuction()

  if (!auction) return

  const funds = await auction.methods.fundsByBidder(connectedAccount).call()

  return web3.utils.fromWei(funds)
}

export const placeBid = async (amount) => {
  const connectedAccount = await getAccount()
  
  if (!connectedAccount) return
  
  const auction = await getAuction()
  
  if (!auction) return
  
  const currentBid = await getCurrentBid()

  const newValue = BigNumber(amount).minus(currentBid).toString()

  await auction.methods.placeBid().send({
    from: connectedAccount,
    value: web3.utils.toWei(newValue)
  })
}

const getLoggedBids = async () => {
  const auction = await getAuction()

  if (!auction) return

  const startBlock = await auction.methods.startBlock().call()

  const pastBids = await auction.getPastEvents('LogBid', {fromBlock: startBlock, toBlock: 'latest'}, 
    (err, events) => {
      if (err) return console.log(err)
      return events
    })

  return pastBids
}

export const getBidHistory = async () => {
  const pastBids = await getLoggedBids()

  if (!pastBids || pastBids.length < 1) return null

  const history = pastBids.map(event => ({
    bidder: event.returnValues[0],
    amount: web3.utils.fromWei(event.returnValues[1].toString(),'ether'),
    tx: event.transactionHash
  }))

  return history
}

export const getBidIncrement = async () => {
  const auction = await getAuction()

  if (!auction) return

  const bidIncrement = await auction.methods.bidIncrement().call()
  const bidIncrementToEth = web3.utils.fromWei(bidIncrement)
  return bidIncrementToEth
}

export const getEthereumPrice = async () => {
  let response = await fetch('https://api.coingecko.com/api/v3/simple/price?vs_currencies=usd&ids=ethereum');
  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  const payload = await response.json()
  const usd = payload.ethereum.usd
  
  return usd
}

export const isAuctionWinner = async () => {
  const account = await getAccount()

  if (!account) return false

  const highestBidder = await getHighestBidder()
  const isOver = await isAuctionOver()

  const isHigestBidder = account.toLowerCase() === highestBidder.toLowerCase()
  return Boolean(isOver && isHigestBidder)
}
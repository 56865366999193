import React from "react"
import styled from "styled-components"
import { theme, device } from "../../../theme"
import Slider from "./silder"

const AboutNftsSection = styled.section`
  min-height: 70vh;
  background-color: ${theme.black};
  color: ${theme.white};
`

const SectionContainer = styled.div`
  padding: 50px 100px;

  @media ${device.tablet} {
    padding: 40px 20px;
  }
`

const SectionTitle = styled.div`
  font-size: 30px;
  text-align: ${(props) => (props.center ? "center" : "left")};
  color: ${theme.primary};
`

const AboutNfts = () => (
  <AboutNftsSection id="aboutNfts">
    <SectionContainer>
      <SectionTitle center>How To Use NFTs</SectionTitle>
    </SectionContainer>
    <Slider />
  </AboutNftsSection>
)

export default AboutNfts

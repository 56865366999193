import React, { useState, useEffect } from "react"
import loadable from '@loadable/component'
import { Switch, Route } from "react-router-dom"

import PurchaseRoute from './purchaseRoute'
import Navbar from "../components/navbar"
import Footer from "../components/footer"
import ArgonLogo from "../components/argon"
import AppContext from "./context"

import { getAccount, getNetworkType } from '../api'
import { GIVEN_PROVIDER } from '../api/web3'

import {
  Intro,
  Landing,
  NftEditions,
  Downloads,
  Merchandise,
  AboutNfts,
} from "./sections"
import WrongNetwork from "./wrongNetwork"

const Gallery = loadable(() => import('./gallery'))
const Privacy = loadable(() => import('./privacy'))

const provider = GIVEN_PROVIDER

const App = () => {
  const [account, setAccount] = useState("")
  const [network, setNetwork] = useState("")

  const getData = async () => {
    try {
      setAccount(await getAccount())
      setNetwork(await getNetworkType())
    } catch (e) {
      console.log(e)
    }
  }

  useEffect(() => getData(), [])
  useEffect(() => {
    if (!provider) return
    provider.on("accountsChanged",  async () => await getData())
    provider.on("chainChanged",     async () => await getData())
    provider.on("connect",          async () => await getData())
    provider.on("disconnect",       async () => await getData())
  }, [])

  const Main = () => (
    <>
      <Landing />
      <Intro />
      {process.env.REACT_APP_TOGGLE_NFTS && <NftEditions />}
      <Downloads />
      <Merchandise />
      <AboutNfts />
    </>
  )

  return (
    <AppContext.Provider value={{
      account: account,
      network: network,
      isAllowedNetwork: network === process.env.REACT_APP_ETHEREUM_NETWORK,
      isAccountConnected: Boolean(account && account.length > 0),
    }}>
      <Switch>
        <PurchaseRoute path="/gallery">
          <Navbar white />
          <Gallery />
        </PurchaseRoute>
        <Route path="/privacy-and-terms">
          <Navbar white />
          <Privacy />
        </Route>
        <Route path="/">
          <Navbar />
          <Main />
        </Route>
      </Switch>
      <ArgonLogo />
      <Footer />
      <WrongNetwork />
    </AppContext.Provider>
  )
}

export default App

import { useState, useEffect } from "react"
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Loader } from "rimble-ui"

import { formatAddress } from "../../../../../utils"
import { device } from "../../../../../theme"
import { getBidHistory } from "../../../../../api/auction"

const Bid = styled.div`
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;

  @media ${device.tablet} {
    width: 95%;
  }
`

const Item = ({address, amount, txId}) => (
  <Bid>
    <div>
      {formatAddress(address)} 
    </div>
    <div>
      <strong>{amount} ETH</strong> 
      {' '}
      <a 
        target="_blank" 
        rel="noopener noreferrer" 
        href={`${process.env.REACT_APP_ETHERSCAN_URL}/tx/${txId}`}
      >
        <FontAwesomeIcon icon={faExternalLinkAlt}/>
      </a>
    </div>
  </Bid>
)

const Items = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [bidHistory, setBidHistory] = useState([])

  useEffect(() => {
    const history = async () => {
      setIsLoading(true)
      setBidHistory(await getBidHistory())
      setIsLoading(false)
    }

    history()
  }, [])

  if (isLoading) return <Loader/>
  if (!bidHistory || bidHistory.length < 1) return (
    <div>
      There is no history yet for this auction.
    </div>
  )

  return (
    bidHistory.map(bid => (
    <Item 
      key={bid.tx} 
      address={bid.bidder} 
      amount={bid.amount} 
      txId={bid.tx}
    />
  )))
}

export default Items
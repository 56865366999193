
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Loader } from 'rimble-ui'
import { theme } from '../../../../../theme'
import Link from "../../../../../components/core/link"
import Unlocked from "../../../../../assets/unlocked.svg"
import { claimAuctionNft, getAuctionNftAvailable } from "../../../../../api"

const UnlockedImage = styled.img`
  height: 20px;
`

const PurchaseInformation = styled.div`
  color: ${theme.secondary};
  text-align: center;
  line-height: 25px;

  h1 {
    line-height: 1.15;
  }
`

const InlineLoader = styled(Loader)`
  display: inline-block;
`

const Winner = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [isAvailable, setIsAvailable] = useState(false)

  useEffect(() => { 
    const getData = async () => {
      setIsLoading(true)
      setIsAvailable(await getAuctionNftAvailable())
      setIsLoading(false)
    }
    getData()
  }, [])

  const handleClaim = async () => {
    setIsLoading(true)
    try {
      await claimAuctionNft()
    } catch (e) {
      console.log(e)
    }
    setIsLoading(false)
  }

  const ClaimNft = () => {
    if (isLoading) return <><InlineLoader /> Pending....</>
    if (isAvailable) return <Link onClick={handleClaim}>Claim NFT</Link>

    return <div>You have claimed this NFT already.</div>
  }
  
  return (
    <PurchaseInformation>
      <h1>You’ve won the Official DR.EV4L 1/1 NFT Auction!</h1>
  
      To collect your NFT and all of your redeemables, click the Unlocked Gallery <UnlockedImage src={Unlocked} alt="unlockedNfts" /> icon at the top right of the website. If you don’t see it, simply refresh the page.
      <br/><br/>
      <ClaimNft />
      <br/>
      To collect your redeemables, please email us at <strong>info@argon.industries</strong>
    </PurchaseInformation>
  )
}

export default Winner
import { theme, device } from "../../theme"
import styled from "styled-components"
import { Link } from "react-router-dom"
import CoreLink from "../core/link"
import BareButton from "../core/bareButton"
import Modal from "../core/modal"

const year = new Date().getFullYear()

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${theme.black};
  color: ${theme.white};
  padding: 40px 100px;
  border-top: 2px solid #1a1a1a;

  @media ${device.tablet} {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 20px;
  }
`

const Items = styled.div`
  display: flex;
  align-items: center;
  @media ${device.tablet} {
  }
`

const Item = styled.div`
  padding: 10px;
`

const FooterLink = styled(Link)`
  color: #fff;
  text-decoration: none;
`

const Footer = () => (
  <Container>
    <Items>
      <Item>
        <Modal
          trigger={<BareButton>Contact</BareButton>}
          title={"Direct all questions to:"}
          content={
            "info@argon.industries"
          }
        />
      </Item>
      <Item>
        <FooterLink to="/privacy-and-terms">Privacy & Terms</FooterLink>
      </Item>
    </Items>
    <div>
      <Item>© {year} <CoreLink secondary href={process.env.REACT_APP_ARGON_URL} target="_blank" rel="noopener noreferrer">Argon Industries</CoreLink></Item>
    </div>
  </Container>
)

export default Footer

import styled from "styled-components"
import { device } from "../../../theme"
import PopupCard from "../../../components/core/card/popup"
import Section from "../../../components/core/section"

import Desktop1 from "../../../assets/downloads/desktop/DREV4L_DESKTOP_WALLPAPER_1.png"
import Desktop2 from "../../../assets/downloads/desktop/DREV4L_DESKTOP_WALLPAPER_2.png"
import Phone1 from "../../../assets/downloads/phone/DREV4L_PHONE_WALLPAPER_1.png"
import Phone2 from "../../../assets/downloads/phone/DREV4L_PHONE_WALLPAPER_2.png"
import Phone3 from "../../../assets/downloads/phone/DREV4L_PHONE_WALLPAPER_3.png"

const Desktop =  `${process.env.REACT_APP_CLOUDFRONT_URL}/desktop.jpg`
const Desktop1Thumb =  `${process.env.REACT_APP_CLOUDFRONT_URL}/desktop_1_thumb.jpg`
const Desktop2Thumb =  `${process.env.REACT_APP_CLOUDFRONT_URL}/desktop_2_thumb.jpg`

const Phone = `${process.env.REACT_APP_CLOUDFRONT_URL}/phone.jpg`
const Phone1Thumb = `${process.env.REACT_APP_CLOUDFRONT_URL}/phone_1_thumb.jpg`
const Phone2Thumb = `${process.env.REACT_APP_CLOUDFRONT_URL}/phone_2_thumb.jpg`
const Phone3Thumb = `${process.env.REACT_APP_CLOUDFRONT_URL}/phone_3_thumb.jpg`

const CardsContainer = styled.div`
  display: flex;
  flex-direction: row;

  @media ${device.tablet} {
    flex-direction: column;
  }
`
const Row = styled.div`
display: flex;
`

const Item = styled.a`
  padding:5px;
`

const Downloads = () => (
  <Section title={"Downloads"} id="downloads">
    <CardsContainer>
      <PopupCard image={Desktop} description={"Desktop Backgrounds"}>
        <div>
        <h5>Click image to download</h5>
          <div>
            <Item download href={Desktop1}><img src={Desktop1Thumb} alt='desktop background 1'/></Item> 
            <Item download href={Desktop2}><img src={Desktop2Thumb} alt='desktop background 2'/></Item> 
          </div>
        </div>
      </PopupCard>
      <PopupCard image={Phone} description={"Phone Backgrounds"}>
        <div>
          <h5>Click image to download</h5>
          <Row>
            <Item download href={Phone1}><img src={Phone1Thumb} alt='phone background 1'/></Item> 
            <Item download href={Phone2}><img src={Phone2Thumb} alt='phone background 2'/></Item> 
            <Item download href={Phone3}><img src={Phone3Thumb} alt='phone background 3'/></Item> 
          </Row>
        </div>
      </PopupCard>
    </CardsContainer>
  </Section>
)

export default Downloads

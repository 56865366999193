

import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Link } from "react-router-dom"

import { device } from "../../theme"
import Unlocked from "../../assets/unlocked.svg"

import { getUserNFTBalance } from "../../api"
import { isAuctionWinner } from "../../api/auction"

const UnlockedImage = styled.img`
height: 30px;
margin-left: 20px;

@media ${device.tablet} {
  height: 50px;
  margin-left: 0px;
  margin-top: 20px;
}
`

const GalleryLink = ({ onClick }) => {
    const [isAccessable, setIsAccessable] = useState(false)

    useEffect(() => {
        const getData = async () => {
            const balance = await getUserNFTBalance()
            
            if (Boolean(balance < 1)) {
              setIsAccessable(await isAuctionWinner())
              return
            }
    
            setIsAccessable(Boolean(balance > 0))
        }
        getData()
    }, [])
    
    if (!isAccessable) return null

    return (
        <Link to="/gallery" onClick={onClick}>
        <UnlockedImage src={Unlocked} alt="unlockedNfts" />
        </Link>
    )
}

export default GalleryLink